<template>
  <div>
    <div class="uk-flex uk-flex-between">
      <div>
        <h1 class="uk-text-left uk-margin-remove-bottom">{{ $t('Sidebar.Transcodes') }}</h1>
      </div>
      <div class="uk-flex">

        <div class="uk-margin-small-right">
          <form class="uk-search uk-width-auto@m uk-search-default" @submit.prevent="">
              <span class="uk-search-icon" uk-search-icon></span>
              <a v-if="searchKey" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch()"></a>
              <input @blur="updateSearchKeyInURL()" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchKey" :placeholder="$t('Generic.Labels.Search')">
          </form>
        </div>

        <div>
          <button :uk-tooltip="$t('Generic.Labels.Filter')" :aria-label="$t('Generic.Labels.Filter')" class="uk-button uk-button-primary uk-align-center uk-float-right uk-margin-small-right" style="padding-left: 10px; padding-right: 10px;">
            <img src="@/assets/filter-icon.svg" :alt="$t('Generic.Labels.Filter')" uk-svg />
          </button>
          <div style="min-width: 150px;" uk-dropdown="mode: hover; pos: bottom-left; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
            <ul class="uk-nav uk-dropdown-nav uk-text-left">
              <li class="uk-text-default" :class="filterClass.all"><a @click="changeFilter('all')">{{$t('Generic.Labels.All')}}</a></li>
              <li class="uk-text-default" :class="filterClass.processing" @click="changeFilter('processing')"><a href="#">{{$t('Pages.Transcodes.Processing')}}</a></li>
              <li class="uk-text-default" :class="filterClass.completed"><a @click="changeFilter('completed')">{{$t('Pages.Transcodes.Completed')}}</a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <hr />

    <vue-good-table
      v-if="transcodes && transcodes.length"
      styleClass="vgt-table uk-table uk-table-small uk-table-striped uk-table-hover uk-table-responsive uk-margin-remove-bottom"
      :columns="tableHeaders.slice(0,5)"
      :sort-options="{
      enabled: true,
      initialSortBy: {field: 'name', type: 'asc'}
      }"
      :rows="formattedTranscodes"
      :line-numbers="false"
      :totalCount="totalTranscodes"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 25, 50, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: this.$t('Pages.PushNotifications.Next'),
        prevLabel: this.$t('Pages.PushNotifications.Prev'),
        rowsPerPageLabel: this.$t('Pages.PushNotifications.RowsPerPage'),
        ofLabel: 'of',
        pageLabel: this.$t('Pages.PushNotifications.Page'), // for 'pages' mode
        allLabel: this.$t('Pages.PushNotifications.All'),
      }"
    >
      <div slot="emptystate" class="vgt-center-align vgt-text-disabled">
        <span >{{$t('Pages.Transcodes.NoTrascodesMatchingSearch')}}</span>
      </div>
      <template slot="table-row" slot-scope="row">
        <span v-if="row.column.field === 'name'">
          <router-link :to="{ name: 'MediaItem', params: { id: orgId, mediaId: row.row.transcodeInfo.source.id }, query: { ref: 'transcodes' } }" style="text-decoration: none;">{{ row.row.name }}</router-link>
        </span>
        <span class="uk-text-center" v-else-if="row.column.field === 'status'">
          <span :class="getTranscodeLabel( row.row.transcodeInfo.status )">{{ $t('Pages.MediaLibrary.TranscodeStatus.' + row.row.transcodeInfo.status)}}</span>
        </span>
        <span v-else-if="row.column.field === 'startDate'">
          {{$d( new Date(row.row.startDate), 'long')}}
        </span>
      </template>
    </vue-good-table>

    <div v-if="fetching && !(transcodes && transcodes.length)">
      <span uk-spinner></span>
    </div>
    <div v-if="!fetching && !(transcodes && transcodes.length) && !searchKey">
      <span class="uk-text-meta">{{$t('Pages.Transcodes.NoData')}}</span>
    </div>
    <div id="transcode-info-modal" uk-modal="bg-close: false; esc-close: false;">
      <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
        <p class="uk-text-meta">{{ cannotDeleteMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Notification from '@/components/util/Notification';
import { isMediaTranscodeDeletable, isMediaTranscodeError, getMediaTranscodesMap  } from '@/utils/transcode';
import { MediaTranscodeType } from '@/utils/enums';

export default {
  name: 'TranscodesView',
  components: {
    VueGoodTable
  },
  data() {
    return {
      fetching: false,
      deletingList: [],
      deleteMediaList: [],
      cannotDeleteMessage: '',
      orgId: this.$route.params.id,
      searchKey: this.$route?.query?.search || '',
      filterType: 'processing',
      loadData: true,
      filterClass: {
        all: '',
        processing: 'uk-active uk-text-bold',
        completed: ''
      },
    }
  },
  computed: {
    ...mapState({
      transcodes: state => (state.venom.org.transcodes) ? state.venom.org.transcodes.nodes : [],
      totalTranscodes: state => (state.venom.org.transcodes) ? state.venom.org.transcodes.totalCount: 0,
      pageInfo: state => (state.venom.org.transcodes?.pageInfo) ? state.venom.org.transcodes.pageInfo : {}
    }),
    tableHeaders () {
      return [{
        label: this.$t('Pages.Transcodes.TableHeading.Name'), 
        field: 'name',
        tdClass: 'uk-text-truncate',
        width: '35%',
        // sortable: true
      }, {
        label: this.$t('Pages.Transcodes.TableHeading.Job'),
        field: 'job',
        width: '5%',
        sortable: false,
      }, {
        label: this.$t('Pages.Transcodes.TableHeading.Format'),
        field: 'format',
        width: '12%',
        sortable: true,
      }, {
        label: this.$t('Pages.Transcodes.TableHeading.StartDate'),
        field: 'startDate',
        width: '10%',
        sortable: true,
      }, {
        label: this.$t('Pages.Transcodes.TableHeading.Status'),
        field: 'status',
        thClass: 'uk-text-center',
        tdClass: 'uk-text-center',
        width: '10%',
        sortable: true,
      }, {
        label: this.$t('Pages.Transcodes.TableHeading.Actions'),
        field: 'actions',
        thClass: 'uk-width-small uk-text-nowrap uk-text-center',
        tdClass: 'uk-width-small uk-text-nowrap uk-text-center',
        width: '10%',
        sortable: false,
      }]
    },
    inProgressTranscodes: function() {
      return this.transcodes.filter( transcode => transcode.transcodeInfo.status === 'IN_PROGRESS' );
    },
    filteredTranscodes () {
      return this.transcodes.filter(transcode => {
        const title = transcode.title.toLowerCase()
        const searchKey = this.searchKey.toLowerCase()
        if (this.filterType === 'all') {
          return title?.includes(searchKey)
        } else if (this.filterType === 'processing') {
          return (transcode.transcodeInfo.status === 'IN_PROGRESS' && title?.includes(searchKey))
        } else {
          return (transcode.transcodeInfo.status !== 'IN_PROGRESS' && title?.includes(searchKey))
        }
      })
    },
    formattedTranscodes () {
      return this.filteredTranscodes.map(transcode => {
        return {
          name: transcode.title,
          job: this.$t('Pages.Transcodes.Transcodes'),
          format: this.$t( `enums.MediaFormats.${transcode.transcodeType}` ),
          startDate: transcode.transcodeInfo.startedAt,
          status: transcode.transcodeInfo.status,
          transcodeInfo: transcode.transcodeInfo
        }
      })
    }
  },
  methods: {
    changeFilter( type ) {
      this.filterType = type;
      this.filterClass = {
        all: '',
        processing: '',
        completed: ''
      }
      this.filterClass[ type ] = 'uk-active uk-text-bold';
    },
    async reTranscodeMedia( mediaId ) {
      const orgId = this.orgId;
      const store = this.$store;
      window.UIkit.modal.confirm( this.$t('Pages.Transcodes.Notifications.TranscodeConfirmation') ).then( async () => {
        const ret = await store.dispatch('reTranscode', { orgId, mediaId });    
        if (ret) {
          Notification.showNotification( this.$t('Pages.Transcodes.Notifications.TranscodeRestarted'), this.$t('Pages.Transcodes.Notifications.RestartedTranscoding', { name: mediaId }) );
        } else {
          Notification.showNotification( this.$t('Pages.Transcodes.Notifications.TranscodeNotRestarted'), this.$t('Pages.Transcodes.Notifications.CouldNotRestart', { name: mediaId }), 'error');
        }
      });
    },
    async fetchOrgTranscodes( isNextPage, endCursor ) {
      const after = isNextPage ? endCursor : '';
      if ( isNextPage && !after ) {
        return;
      }
      if (!isNextPage) // Setting spinner only for first load
        this.fetching = true;
      const response = await this.$store.dispatch('fetchTranscodes', { 
        orgId: this.orgId,
        onlyTranscodeStatuses: ["IN_PROGRESS", "COMPLETED", "ERROR"],
        after,
        first: isNextPage ? 20 : 50,
        isNextPage
      });
      this.fetching = false;
      this.$nextTick(() => {
        if (response?.pageInfo?.hasNextPage && this.loadData) {
          this.fetchOrgTranscodes(true, response.pageInfo.endCursor)
        }
      })
    },
    getTranscodeLabel( status ) {
      switch (status.toLowerCase()) {
      case 'completed':
          return "uk-label uk-label-success";
        case 'error':
          return "uk-label uk-label-danger";
        default:
          return "uk-label";
      }
    },
    MP4TranscodesLength ( media ) {
      return ( media.transcodeSource?.transcodes || [])
          .filter( m => m.transcodeType === MediaTranscodeType.LOWER_RESOLUTION_MP4S ).length;
    },
    async deleteTranscode( transcode ) {
      if ( isMediaTranscodeDeletable( transcode ) ) {
        let confirmMessage = this.$t( 'DeleteModal.DeleteTranscodeMedia', { name: transcode.title } );
        if ( transcode.transcodeType === MediaTranscodeType.LOWER_RESOLUTION_MP4S ) {
          confirmMessage = this.$tc( 'DeleteModal.DeleteTranscodeMediaMP4', this.MP4TranscodesLength( transcode ), { name: transcode.title } );
        }

        window.UIkit.modal.confirm( confirmMessage ).then(async () => {
          this.deletingList.push( transcode );

          const { transcodeType } = transcode;
          if ( !isMediaTranscodeError( transcode ) ) {
            if ( transcodeType === MediaTranscodeType.CLEARVR ) {
              this.deleteMediaList = [ transcode.id ];
            } else {
              this.deleteMediaList = Array.from( getMediaTranscodesMap( transcode.transcodeSource )[transcodeType]).map( mediaItem => mediaItem.id );
            }
          } else {
            this.deleteMediaList = [ transcode.id ];
          }
          await this.deleteMedia( this.deleteMediaList );

          await this.fetchOrgTranscodes( false );
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.InteractiveVideos.Notification.DeletedSuccessful', { name: transcode.title } ) );
        }, function () {
          // cancelled
        });
      } else {
        this.cannotDeleteMessage = this.$t( 'DeleteModal.CannotDeleteTranscode', { name: transcode.title } );
        window.UIkit.modal('#transcode-info-modal').show();
      }
    },
    async deleteMedia ( deleteMediaList ) {
      try {
        await Promise.all( deleteMediaList.map( async media => {
          await this.$store.dispatch('deleteMedia', { mediaId: media })
        }) );
      } catch ( err ) {
        // exception
      }
    },
    transcodeRoute( transcode ) {
      if ( transcode.transcodeInfo?.source?.id ) {
        return { name: 'MediaItem', params: { id: this.orgId, mediaId: transcode.transcodeInfo?.source?.id }, query: { ref: 'transcodes' } };
      } else {
        return {};
      }
    },
    updateSearchKeyInURL () {
      const url = new URL(window.location);
      url.searchParams.set('search', this.searchKey);
      window.history.pushState({}, '', url);
    },
    clearSearch () {
      this.searchKey = '';
      const url = new URL(window.location);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }
  },
  mounted() {    
    this.fetchOrgTranscodes( false );
    document.documentElement.scrollTop = 0
  },
  beforeDestroy () {
    this.loadData = false
  },
}
</script>

<style lang="scss">
table.vgt-table, .vgt-input {
  background: none;
  border: 1px solid var(--border-color);
}
.vgt-inner-wrap {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vgt-global-search label {
  position: absolute;
  left: 10px;
}
.vgt-global-search__input .input__icon .magnifying-glass  {
  margin-left: 0px;
}
.vgt-global-search .vgt-input {
  width: 40%;
  margin-left: -40px;
  height: 40px;
  padding-left: 40px;
  color: var(--text-color);
}
.vgt-global-search .vgt-input::placeholder {
  color: var(--text-color);
}
table.vgt-table {
  font-size: 12px;
  border: 0px;
}
table.vgt-table td {
  border: 0px;
  color: var(--text-color);
}
.vgt-table thead th {
  background: none;
  color: var(--white-color);
  border: 0px;
  width: initial;
}
.vgt-table th.line-numbers {
  background: none;
  border-right: 1px solid var(--border-color);
}
.vgt-table th.sortable button:after {
  border-bottom: 5px solid var(--white-color);
}
.vgt-table th.sortable button:before {
  border-top: 5px solid var(--white-color);
}
.vgt-table .custom-center span {
  padding-left: 10px;
}
.vgt-wrap__footer {
  background: none;
  border: 0px;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  background: none;
  border: 1px solid var(--border-color);
  color: #909399;
  padding: 2px;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 13px;
}
.floating-button {
  position: absolute;
  right: 30px;
  margin-top: 60px;
  z-index: 1;
}

.new-btn {
  padding: 0 25px;
}
.vgt-table th.sortable button {
  width: auto;
  position: relative;
  margin-left: 5px;
  top: -.25rem;
}
</style>