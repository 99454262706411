import { isMediaTypeHLS, isMediaTypeMP4, isMediaTypeCLEARVR } from './mediaItem';
import { JobStatusType, MediaClass, MediaTranscodeType } from './enums';

export const clearVRSupportedProjections = [
    'EQUIRECTANGULAR',
    'EQUIRECTANGULAR_180',
    'EQUIRECTANGULAR_3D_L_OVER_R',
    'EQUIRECTANGULAR_180_3D_SIDE_BY_SIDE',
    'EQUIRECTANGULAR_3D_SIDE_BY_SIDE',
    'CUBE_MAP'
];

export function isTranscodableToClearvr ( resolution, projection ) {
    return ( resolution.width > 3840 && resolution.height > 1920 )
        && clearVRSupportedProjections.includes( projection );
}

export function isTranscodableToMP4 ( resolution ) {
    return resolution.height > 720;
}

export function getLabelFriendlyResolution ( resolution ) {
    const { width, height } = resolution;

    const h = height === 1024 ? '2K'
        : height === 960 ? '1080p'
        : height === 640 ? '720p' : '';

    const k = width > 7200 ? '8'
        : width > 6000 ? '6'
        : width > 3800 ? '4'
        : width > 2048 ? '2' : '';

    const kText = k ? `  (${k}K)` : '';
    const htext = h || `${height}p`;
    return `${htext}${kText}`;
}

export function isMediaTranscodeStatus ( media, status ) {
    return media.transcodeStatus === status;
}

export function isMediaChildsTranscodeStatus ( media, status ) {
    return ( media.transcodes || []).some( mediaChild => isMediaTranscodeStatus( mediaChild, status ) );
}

export function isMediaReady ( media ) {
    const { transcodeStatus } = media;

    return transcodeStatus !== JobStatusType.IN_PROGRESS
        && transcodeStatus !== JobStatusType.ERROR;
}

export function isMediaProbeSupport ( media ) {
    return media.mediaClass === MediaClass.VIDEO && isMediaReady( media ) && (
        isMediaTypeHLS( media.mediaType ) || isMediaTypeMP4( media.mediaType ) );
}

export function getMediaTranscodesMap ( media ) {
    const addMapValue = ( map, key, value ) => {
        if ( !Array.isArray( map[key]) ) map[key] = [ ];

        map[key].push( value );
        return map;
    };

    return getMediaTranscodes( media )
        .reduce( ( map, transcode ) => addMapValue( map, getMediaTranscodeType( transcode ), transcode ), {});
}

export function getMediaTranscodes ( media, defaultTranscodes = []) {
    return media.transcodes || defaultTranscodes;
}

export function getTranscodeTypeFromMediaType ( mediaType, transcodeType = null ) {
    if ( isMediaTypeCLEARVR( mediaType ) )
        transcodeType = MediaTranscodeType.CLEARVR;
    else if ( isMediaTypeHLS( mediaType ) )
        transcodeType = MediaTranscodeType.HLS_ABR;
    else if ( isMediaTypeMP4( mediaType ) )
        transcodeType = MediaTranscodeType.LOWER_RESOLUTION_MP4S;

    return transcodeType;
}

export function getMediaTranscodeType ( media ) {
    const { mediaType, transcodeType } = media;

    return transcodeType || getTranscodeTypeFromMediaType( mediaType );
}

export function isMediaProbeInProgress ( media ) {
    return media?.probeStatus === JobStatusType.IN_PROGRESS;
}

export function isMediaTranscodeInProgress ( media ) {
    return media?.transcodeStatus === JobStatusType.IN_PROGRESS;
}

export function isMediaTranscoded ( media ) {
    return Boolean( media?.transcodeSource?.id );
}

export function isOrganizationTranscodeSupportClearVR ( org ) {
    const { clearVRConfig } = org || {};
    const { apiAuthToken, customerId } = clearVRConfig || {};

    return Boolean( apiAuthToken && customerId );
}

export function isOrganizationTranscodeSupport ( org, mediaTranscodeType ) {
    if ( mediaTranscodeType === MediaTranscodeType.CLEARVR ) {
        return isOrganizationTranscodeSupportClearVR( org );
    }

    return true;
}

export function getMediaLinkedContent ( media, defaultLinkedContent = []) {
    return ( media && media.linked?.nodes ) || defaultLinkedContent;
}
  
export function isMediaLinkedContent ( media ) {
    return Boolean( getMediaLinkedContent( media ).length );
}
  
export function isMediaTranscodeError ( media ) {
    return media && media.transcodeInfo.status === JobStatusType.ERROR;
}
  
export function isMediaTranscodeDeletable ( media ) {
    if ( isMediaTranscodeError( media ) )
        return true;
  
    return !isMediaLinkedContent( media );
}